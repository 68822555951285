.ArrowTop {
	position: fixed;
	right: 3%;
	bottom: 5%;

	width: 50px;
	height: 50px;

	background-image: url("../img/arrow-top.svg");
	background-repeat: no-repeat;
	background-size: cover;
	border: none;
	box-shadow: 0 0 5px 0 gray;

	transition: all 0.5s;
}

.ArrowTop:hover {
	box-shadow: 0 0 5px 0 red;
	transform: scale(1.05);
}