.Blog {
	background-color: #514547;
	background-image: url("../../img/lamp.png"),
		url("../../img/blog-bg.jpg");
	background-repeat: no-repeat,
		no-repeat;
	background-size: 25%,
		cover;
	background-position: 93% 97%,
		0 0;
	box-shadow: 0 0 15px 0 #514547;
}