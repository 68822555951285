.Catalog {
	min-height: auto;
	background-image: linear-gradient(to top, #0d2d57 0%, #82bce9 100%);
}

.Catalog .cards {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 1rem;
	padding: 0;
}

.category {
	display: flex;
	align-items: center;
}

.category p {
	margin-bottom: 0;
	margin-right: 10px;
	text-transform: uppercase;
	font-size: 0.9rem;
}

.category-list {
	border-color: rgb(165, 6, 6);
	padding: 2px 10px;
}