.accordion {
	margin-top: 1rem;
	padding: 30px 25px;
	border-radius: 15px;
	background-color: rgb(66, 122, 173);
}

.accordion-item {
	margin-bottom: 15px;
}

.accordion-item:last-child {
	margin-bottom: 0;
}

.accordion-header {
	margin: 0;
	transition: all 0.25s;
}

.accordion-header:hover {
	background-color: gold;
}

.accordion-button {
	width: 100%;
	padding: 10px;
	padding-left: 20px;

	font-size: 1.75rem;
	text-align: left;
	text-transform: uppercase;
	color: rgb(10, 11, 14);
	border: none;
	background-color: transparent;
	background-image: linear-gradient(90deg, #ffffffa2 0%, #b9e3fdb4 100%);
	box-shadow: 2px 2px 5px rgb(183, 203, 233);
}

.accordion-body {
	padding: 20px;
	font-family: "Georgia", serif;
	font-size: 1.25rem;
	text-align: justify;
}

.accordion-body::first-letter {
	font-size: 2.25rem;
	color: gold;
}